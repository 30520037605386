export const environment = {
  production: false,
  PUBLIC_API: 'https://api.staging.beta.qualio.com',
  API_URL: 'https://api.migrations.staging.qualio.com',
  APP_URL: 'https://app.staging.migrations-manager.qualio.com',
  environmentName: 'staging',
  loginUrl: 'https://app.staging.beta.qualio.com/login',
  authSuccessRefreshIntervalMillis: 60000,
  authErrorRetryIntervalMillis: 5000,
  translationsUrl: '/translations/{{lng}}/{{ns}}.json',
  LOG_LEVEL: 'debug',
  ldClientSideId: '61e6a31060139c1610d1516b',
};
